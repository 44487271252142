import {get, post} from "../../https/index"

const WorkorderApi = {

    /**
     * 查询工单
     * @param id 工单 ID
     */
    find: (id) => get(`/workorder/workorder/${id}`),
    /**
     * 查询用户正在进行中的工单
     * @param accountId
     */
    ongoingWorkorder: accountId => get(`/workorder/account/${accountId}/progressing-workorder`),

    /**
     * 查询最新工单
     * @param params
     */
    findNewWorkorder: params => get("/workorder/workorders/newest", params),

    /**
     * 获取工单数量
     * @param params
     */
    findWorkorderNumber: params => get("/workorder/workorder/statistics/count", params),

    /**
     * 获取已评价工单数量
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderEvaluatedNumber: params => get("/workorder/workorder/statistics/count/evaluated", params),

    /**
     * 查询待指派工单数
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findUnassignedWorkorderNumber: params => get("/workorder/unassigned-workorder/count", params),

    /**
     * 工作平均接单时长
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderAverageReceiveTime: params => get(`/workorder/account/${params.accountId}/workorder/average-receive-duration`, params),

    /**
     * 工作平均完成时长
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderAverageFinishTime: params => get(`/workorder/account/${params.accountId}/workorder/average-finish-duration`, params),

    /**
     * 工单回访率
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderEvaluated: params => get("/workorder/workorder/evaluated-ratio", params),

    /**
     * 获取工单满意度
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderFeedbackScore: params => get("/workorder/workorder/statistics/feedback-score/distributed", params),

    /**
     * 工单报修渠道
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderRepairWay: params => get("/workorder/workorder/origin-count", params),

    /**
     * 获取工单完成时效
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderFinishTime: params => get("/workorder/workorder/prescription", params),

    /**
     * 根据ID查询工单详情
     * @param workorderId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderInfo: workorderId => get(`/workorder/workorder/${workorderId}`),

    /**
     * 最近12个月工单趋势
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderTrend: params => get("/workorder/workorder/new-workorder-count/latest-12-month", params),

    /**
     * 获取工程师近三十天完成工单数
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountWorkorderNumberToThirtyDay: accountId => get(`/workorder/account/${accountId}/handle-workorder/count/latest-30days`),

    /**
     * 获取工程师近三十天处理工单时长
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountWorkorderTimeToThirtyDay: accountId => get(`/workorder/account/${accountId}/workorder/progressing-duration/latest-30days`),

    /**
     * 获取用户近三十天工作状态时长
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountWorkStateTimeToThirtyDay: accountId => get(`/user/account/${accountId}/work-duration/latest-30days`),
    /**
     * 获取个人状态
     * @param data
     */
    findAccountWorkState: data => {
        let {accountId , date} = data
        return get(`/user/situation/record/${accountId}?date=${date}`)
    },
    /**
     * 获取用户守时率
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountWokrorderPunctual: accountId => get(`/workorder/account/${accountId}/workorder/punctual-percentage`),

    /**
     * 获取用户平均接单时间
     * @param accountId
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountAvgReceiveTime: accountId => get(`/workorder/account/${accountId}/workorder/average-receive-duration`),

    /**
     * 获取工程师近12个月满意度反馈
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findAccountFeedbackToTwelveMonth: params => get("/workorder/workorder/statistics/feedback-score/distributed/latest-12-month", params),

    /**
     * 查询工单问题发布
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDepartmentWorkorderQuestion: params => get("/workorder/workorder/statistics/distribute", params),

    /**
     * 查询单位工单数量
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDepartmentWorkorderNumber: params => post("/workorder/workorder/client-departments/count", params),

    /**
     * 查询设备工单数量
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDeviceWorkorderNumber: params => post("/workorder/workorder/devices/count", params),

    /**
     * 查询单位满意度
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDepartmentSatisfaction: params => get("/workorder/workorder/statistics/degree-of-satisfaction", params),

    /**
     * 查询近30天工单排行
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderRankStatistics: () => get("/workorder/workorder/statistics/handler-latest-30-days"),

    /**
     * 批量查询用户工单数量
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findBatchWorkorderCount: params => get("/workorder/users-workorder-count", params),

    /**
     * 查询用户工单好评率
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkorderGoodReview: params => get("/workorder/workorder/statistics/degree-of-satisfaction", params),

    /**
     * 查询超期工单 矫正版
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findDelayedWorkorder: params => get("/workorder/workorder/statistics/count/delayed", params),

    /**
     * 查询泰狐智能运维助手在线接单情况
     * @param params
     * @returns {Promise<AxiosResponse<any>>}
     */
    findWorkRecCount: params => get("/resource/ahri/work-rec/count", params),
    /**
     * 查询泰狐智能运维助手在线接单情况
     */
    findWorkorderScreenPage: data => post("/workorder/workorder/screen/page", data),
    /**
     * 自定义增加的数量
     */
    addNum : () => get("/workorder/workorder/add/num"),
}

export default WorkorderApi;
