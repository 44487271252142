import React, {Component} from 'react';

import styles from "./index.module.css"
import PageTitle from "../../../components/title/PageTitle";
import EdgeBorder from "../../../components/box/EdgeBorder";
import SidebarTitle from "./components/SidebarTitle";
import DeviceMapSidebarList from "./components/DeviceMapSidebarList";
import DeviceApi from "../../../api/device/DeviceApi";
import EventBus from "../../../utils/EventBus";

import oneDeviceIcon from "../../../asset/device/one_device_icon.png"
import moreDeviceIcon from "../../../asset/device/more_device_icon.png"
import moreDeviceQuestionIcon from "../../../asset/device/more_question_icon.png"
import axios from "axios";
import DeviceQuestionStatistics from "./components/DeviceQuestionStatistics";
import DeviceInsertManufacturer from "./components/DeviceInsertManufacturer";
import WorkorderApi from "../../../api/workorder/WorkorderApi";
import {withNavigation} from "../../../utils/WithPlusClass";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-16 16:54:51
 * @description  : 设备地图
 */
class DeviceMap extends Component {

    deviceMap;
    deviceCluster;
    deviceQuestionCluster;

    deviceQuestionStatisticsRef = React.createRef();
    deviceInsertManufacturer = React.createRef();

    state = {
        deviceList: [],
        deviceMakerData: [],
        renderDeviceQuestionList: [],
        workorderList: [],
        compareWorkorderList: [],
        deviceListLoading: false,
        questionLoading: false,
        deviceCurrent: 1,
        workorderCurrent: 1,
        pageSize: 10
    }

    componentDidMount() {
        const lnglat = localStorage.getItem('lnglat');
        const coordinates = lnglat ? lnglat.split(",").map(Number) : [114.973172, 25.817861];
        this.initMap(coordinates);
        this.requestDevice();
        this.requestAllDevice();
        EventBus.on("toggleDeviceMap", ({type}) => {
            this.deviceListQuestionToggle(type);
        })
        //设备分页
        EventBus.on("togglePagination", ({page}) => {
            this.setState({deviceCurrent: page}, () => {
                this.requestAllDevice();
            })
        })

        // 问题分页
        EventBus.on("toggleWorkorderPagination", ({page}) => {
            this.setState({workorderCurrent: page}, () => {
                this.requestWorkorderListToIds();
            })
        })
    }

    componentWillUnmount() {
        this.deviceMap.destroy();
    }

    /**
     * 初始化地图
     */
    initMap = (center) => {
        this.deviceMap = new global.AMap.Map("device-map-container", {
            pitch: 0,
            viewMode: "3D",
            terrain: true,
            zoom: 15,
            resizeEnable: true,
            zooms: [4.6, 18],
            center,
            mapStyle: "amap://styles/darkblue"
        });
    }

    /**
     * 设备列表 <==> 设备问题列表切换
     * @param type
     */
    deviceListQuestionToggle = (type) => {
        if (type === "list") {
            this.deviceQuestionCluster.setMap(null);
            this.requestAllDevice();
            this.requestDevice();
        }
        if (type === "question") {
            // this.deviceCluster.setMap(null);
            // this.requestDeviceWorkorder();
            this.requestWorkorderListToIds();
        }
    }

    /**
     * 销毁并重新初始化地图
     */
    destroyToInitMap = () => {
        this.deviceMap.destroy();
        this.initMap();
    }

    /**
     * 清除地图标记物
     */
    clearMarkersAndPoints = () => {
        this.deviceMap.clearMap();
    };

    /**
     * 自定义设备列表非聚合点样式
     * @param item
     */
    renderDeviceListMaker = (item) => {
        let content = `<div class="maker-box">
                          <img src="${oneDeviceIcon}" style="width: 60px;">
                          <span class="maker-name" style="left: 22px">${item?.count}</span>
                        </div>`
        item.marker.setContent(content)
    }

    /**
     * 自定义设备列表聚合点样式
     * @param item
     */
    renderDeviceListClusterMarker = (item) => {
        let content = `<div class="maker-box">
                          <img src="${moreDeviceIcon}" style="width: 60px;">
                          <span class="maker-name" style="left: 22px">${item?.count}</span>
                        </div>`
        item.marker.setContent(content)
    };

    /**
     * 自定义设备问题监控非聚合点样式
     * @param item
     */
    renderDeviceQuestionMaker = (item) => {
        let content = `<div class="maker-box">
                          <img src="${moreDeviceQuestionIcon}" style="width: 60px;">
                          <span class="maker-name" style="left: 22px">1</span>
                        </div>`
        item.marker.setContent(content)
    }

    /**
     * 自定义设备问题监控聚合点样式
     * @private
     * @param item
     */
    renderDeviceQuestionClusterMarker = function (item) {
        let content = `<div class="maker-box">
                          <img src="${moreDeviceQuestionIcon}" style="width: 60px;">
                          <span class="maker-name" style="left: 22px">${item?.count}</span>
                        </div>`
        item.marker.setContent(content)
    };

    /**
     * 获取所有设备信息 （做缓存）
     * @private
     */
    renderAllDevice = () => {
        DeviceApi.requestAllDevice().then(res => {
            localStorage.setItem('deviceIds', res.data.map(item => item.id))
        })
    }

    /**
     * 查询设备工单 并在地图标点
     */
    requestDeviceWorkorder = () => {

        this.setState({questionLoading: true})
        const deviceIds = localStorage.getItem('deviceIds');
        const coordinates = deviceIds ? deviceIds.split(",") : this.state.renderDeviceQuestionList.map(item => item.id);
        console.log( coordinates)
        axios.post(`${global.config.url}/workorder/workorder/list`,
            {resourceIds: coordinates,relateResource: true},
            {headers: {"ContentType": "application/json;charset=UTF-8"}})
            .then(res => {
                this.setState({compareWorkorderList: res.data.data, workorderList: res.data.data})
                for (let workoder of res.data.data) {
                    for (let device of this.state.renderDeviceQuestionList) {
                        if (workoder.resourceId === device.id) {
                            workoder.lnglat = device.lnglat
                        }
                    }
                }
                // this.deviceQuestionCluster = new global.AMap.MarkerCluster(
                //     this.deviceMap,
                //     res.data.data.filter(item => item.lnglat) // 过滤出有 lnglat 属性的项
                //         .map(item => ({ lnglat: item.lnglat.split(",")})),
                //     {
                //         gridSize: 80,
                //         renderClusterMarker: this.renderDeviceQuestionClusterMarker, // 自定义聚合点样式
                //         renderMarker: this.renderDeviceQuestionMaker // 自定义非聚合点样式
                //     }
                // );
                // this.deviceQuestionCluster.on("click", this.onQuestionClusterClick);
            }).catch(err => {
            console.error("request device workorder failed", err)
        }).finally(() => {
            this.setState({questionLoading: false})
        })
    };

    /**
     * 根据工单ID 查询所有工单
     * @param workorderIds
     */
    requestWorkorderListToIds = () => {
        this.setState({questionLoading: true})
        const deviceIds = localStorage.getItem('deviceIds');
        const coordinates = deviceIds ? deviceIds.split(",") : this.state.renderDeviceQuestionList.map(item => item.id);
        console.log(coordinates)
        // axios.post(`${global.config.url}/workorder/workorder/screen/list`,
        //     {resourceIds: coordinates,relateResource:true},
        //     {headers: {"ContentType": "application/json;charset=UTF-8"}})
        //     .then(res => {
        //         this.setState({workorderList: res.data.data})
        //     }).catch(err => {
        //     console.error("request device workorder failed", err)
        // }).finally(() => {
        //     this.setState({questionLoading: false})
        // })
        let data = {
            resourceIds: coordinates,
            relateResource: true,
            current:this.state.workorderCurrent,
            pageSize:this.state.pageSize
        }
        WorkorderApi.findWorkorderScreenPage(data).then(res => {
            console.log(res.data)
            this.setState({workorderList: res.data.records,workorderListTotal: res.data.total})
        }).catch(err => {
            console.error("request device workorder failed", err)

        }).finally(() => {
            this.setState({questionLoading: false})
        })

    }

    /**
     * 问题地图点击事件
     */
    onQuestionClusterClick = value => {
        let obj = [];
        value.clusterData.forEach(item => {
            obj = `${obj}|${item.lnglat.lng},${item.lnglat.lat}`;
        });
        let newObj = obj.replace(/^\|/g, "").split("|");
        let lnglats = [...new Set(newObj)];
        let workorderIds = []
        this.state.compareWorkorderList.forEach(item => {
            lnglats.forEach(itemY => {
                if (item.lnglat === itemY) {
                    workorderIds.push(item.id)
                }
            })
        })
        // 以上暂时不需要
       // this.requestWorkorderListToIds()
    }

    /**
     * 获取设备并在地图标点
     * @param data
     */
    requestDevice = data => {
        DeviceApi.queryCountyInside(data).then(response => {
            this.setState({deviceMakerData: response.data})
            this.deviceCluster = new global.AMap.MarkerCluster(
                this.deviceMap,
                response.data.map(item => ({lnglat: item.split(",")})),
                {
                    gridSize: 80,
                    renderClusterMarker: this.renderDeviceListClusterMarker, // 自定义聚合点样式
                    renderMarker: this.renderDeviceListMaker // 自定义非聚合点样式
                }
            );
            this.deviceCluster.on("click", this.onClusterClick);
        }).catch(error => console.warn("Request device failed.", error));
    };

    /**
     * 地图点  点击事件
     * @param value
     */
    onClusterClick = value => {
        this.onQuestionClusterClick(value)// 更新问题
        localStorage.setItem("lnglat", [value.lnglat.lng, value.lnglat.lat])
        this.setState({current: 1}, () => {
            console.log("onClusterClick", value)
        })
        let obj = [];
        value.clusterData.forEach(item => {
            obj = `${obj}|${item.lnglat.lng},${item.lnglat.lat}`;
        });
        let newObj = obj.replace(/^\|/g, "").split("|");
        let lnglats = [...new Set(newObj)].join("|");
        this.setState({renderDeviceQuestionList: []})
        localStorage.setItem("lnglats", lnglats)
        this.requestAllDevice({lnglats})
    };

    /**
     * 获取所有设备
     * @param data
     */
    requestAllDevice = (data) => {
        let lnglats = localStorage.getItem("lnglats")
        const deviceIds = localStorage.getItem('deviceIds');
        this.setState({deviceListLoading: true})
        // 为空就取缓存的值
        if (!data?.lnglats) {

            if(lnglats){
                data = {lnglats}
            }
        }
        DeviceApi.requestAllDevice(data).then(res => {
           // this.setState({renderDeviceQuestionList: res.data})
            this.requestDeviceWorkorderNumber(res.data.map(item => item.id))
        })

        // 添加分页参数
        data = {...data, current:this.state.deviceCurrent,pageSize:this.state.pageSize}

        DeviceApi.findLnglatDevice(data).then(res => {
            // if (!data) {
            //     this.setState({renderDeviceQuestionList: res.data})
            // }
            let {records,total} = res.data
            if (records){
                records.forEach(item => item.count = 0)
                this.setState({deviceList: records,deviceListTotal: total}, () => {
                    if (lnglats || deviceIds){
                        //this.setState({renderDeviceQuestionList: res.data.records})
                        //this.requestDeviceWorkorderNumber(records.map(item => item.id))
                    }else {
                        console.log("第一次没点地图")
                        this.renderAllDevice()
                    }
                })
            }
        }).catch(err => {
            console.error("request all device failed", err)
        }).finally(() => {
            this.setState({deviceListLoading: false})
        })

    }

    /**
     * 查询设备工单数
     * @param deviceIds
     */
    requestDeviceWorkorderNumber = (deviceIds) => {
        localStorage.setItem("deviceIds", deviceIds)
        WorkorderApi.findDeviceWorkorderNumber(deviceIds).then(res => {
            let {deviceList} = this.state
            deviceList.forEach(device => {
                res.data.forEach(count => {
                    if (device.id === count.name) {
                        device.count = count.value
                    }
                })
            })
            this.setState({deviceList})
            this.requestWorkorderListToIds()
        }).catch(err => {
            console.error("request device workorder number failed", err)
        })
    }

    /**
     * 打开问题统计弹窗
     */
    openQuestionStatistics = () => {
        this.deviceQuestionStatisticsRef.current.show();
    }

    /**
     * 打开接入厂商弹窗
     */
    openInsertManufacturer = () => {
        //this.deviceInsertManufacturer.current.show();
        this.props.navigate('/home/manufacturer');
    }

    render() {
        return (<div className={styles.device_map_page}>
            <PageTitle title="赣州市信创数字化设备监控中心" backIcon/>
            <div className={styles.device_map_box}>
                <div className={styles.map} id="device-map-container">
                    <div className={styles.device_question_manufacturer}>
                        <span onClick={this.openQuestionStatistics}><img
                            src={require("../../../asset/device/device_question_record_icon.png")}
                            alt=""/>问题统计</span>
                        <span onClick={this.openInsertManufacturer}><img
                            src={require("../../../asset/device/device_manufacturer_icon.png")}
                            alt=""/>接入厂商</span>
                    </div>
                    <EdgeBorder/>
                </div>
                <div className={styles.device_map_sidebar}>
                    <SidebarTitle/>
                    <DeviceMapSidebarList deviceList={this.state.deviceList}
                                          deviceCurrent={this.state.deviceCurrent}
                                          workorderCurrent={this.state.workorderCurrent}
                                          deviceListTotal={this.state.deviceListTotal}
                                          workorderListTotal={this.state.workorderListTotal}
                                          deviceQuestionList={this.state.workorderList}
                                          deviceListLoading={this.state.deviceListLoading}
                                          questionLoading={this.state.questionLoading}
                                          refreshDeviceList={this.requestAllDevice}
                                          refreshDeviceQuestionList={this.requestWorkorderListToIds}/>
                </div>
            </div>
            <DeviceQuestionStatistics ref={this.deviceQuestionStatisticsRef}/>
            <DeviceInsertManufacturer ref={this.deviceInsertManufacturer}/>
        </div>)
    }
}

export default withNavigation(DeviceMap)
