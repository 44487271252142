import React, {Component} from 'react';
import styles from "./index.module.css"
import * as echarts from "echarts";
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import EventBus from "../../../../../utils/EventBus";
import RenderWorkorderProgress from "../../../../../components/workorder/RenderWorkorderProgress";
import {withNavigation} from "../../../../../utils/WithPlusClass";
import RenderEmptyData from "../../../../../components/empty/RenderEmptyData";
import {timeUsageFormat} from "../../../../../utils/tool";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-20 14:21:39
 * @description  : 工单监控左边内容
 */
class WorkorderLeftContent extends Component {

    chart;
    workorderMonitorRef = React.createRef();
    workorderDetailDialogRef = React.createRef();
    newWorkorderTimer;

    state = {
        data: [],
        newWorkorder: [],
        workorderEvaluated: "0%",
        workorderAverageReceiveTime: 0,
        workorderAveageFinishTime: 0,
        workorderFeedbackScore: []
    }

    componentDidMount() {
        this.init();
        this.requestAllServiceData();
        this.requestWorkorderFeedbackScore();
        EventBus.on("toggleCountyData", ({cityName}) => {
            clearInterval(this.newWorkorderTimer);
            if (cityName === "赣州市") {
                this.requestAllServiceData();
                this.requestWorkorderFeedbackScore();
                this.requestNewWorkorder();
                this.intermissionsRequest();
                return;
            }
            this.requestAllServiceData(cityName);
            this.requestNewWorkorder(cityName);
            this.requestWorkorderFeedbackScore(cityName);
            this.intermissionsRequest(cityName);
        })
        this.requestNewWorkorder();
        this.intermissionsRequest();
    }

    componentWillUnmount() {
        // 销毁图表实例
        this.chart.dispose();
        clearInterval(this.newWorkorderTimer)
    }

    /**
     * 工单满意度图标配置项
     */
    options = () => ({
        tooltip: {
            trigger: "item",
            formatter: "{b} : {c} ({d}%)"
        },
        series: [
            {
                name: "满意度",
                type: "pie",
                radius: "80%",
                center: ["50%", "60%"],
                data: [
                    {value: this.state.workorderFeedbackScore[2]?.value, name: "非常满意", itemStyle: {color: "#F955FF"}},
                    {value: this.state.workorderFeedbackScore[1]?.value, name: "满意", itemStyle: {color: "#2B2BC6"}},
                    {value: this.state.workorderFeedbackScore[0]?.value, name: "不满意", itemStyle: {color: "#2D5DC8"}}
                ],
                // roseType: "radius",
                label: {
                    alignTo: 'edge',
                    formatter: '{d}%\n{name|{b}}',
                    minMargin: 1,
                    edgeDistance: 10,
                    lineHeight: 15,
                    color: "#FFFFFF",
                    rich: {
                        time: {
                            fontSize: 10,
                            color: '#FFFFFF'
                        }
                    }
                },
                labelLine: {
                    // showAbove: true,
                    lineStyle: {
                        color: "rgba(255, 255, 255, 0.3)"
                    },
                    length: 5,
                    length2: 8
                },
                animationType: "scale",
                animationEasing: "bounceOut",
                animationDelay: function () {
                    return Math.random() * 200;
                }
            }
        ]
    })

    /**
     * 初始化图表
     */
    init = () => {
        this.chart = echarts.init(document.getElementById("workorder_satisfied_chart"));
        this.chart.setOption(this.options())
    }

    /**
     * 工单监控点击事件
     * @param index
     * @param item
     */
    itemClick = (index, item) => {
        this.props.navigate("/home/workorder/detail", {state: {workorderId: item.id}})
        // const itemDom = document.getElementById(item.id);
        // const parentDom = document.getElementById("workorder_monitor_list");
        // let top = itemDom.offsetTop - parentDom.scrollTop + parentDom.offsetTop
        // console.log(top)
        // if (top >= 110 && top < 216) {
        //     top -= 105
        // }
        // if (top >= 216 && top <= 313) {
        //     top -= 80
        // }
        //
        // if (top > 313 && top <= 358) {
        //     top -= 53
        // }
        //
        // if (top > 358) {
        //     top -= 53
        // }
        // this.workorderDetailDialogRef.current.show(item, top);
    }

    /**
     * 调用服务质量所有接口
     * @param cityName
     */
    requestAllServiceData = (cityName = null) => {
        this.requestWorkorderAverageReceiveTime(cityName);
        this.requestWorkorderAverageFinishTime(cityName);
        this.requestWorkorderEvaluated(cityName);
    }

    /**
     * 平均接单时长
     * @param county
     */
    requestWorkorderAverageReceiveTime = (county = null) => {
        WorkorderApi.findWorkorderAverageReceiveTime({accountId: 0, city: "赣州市", county}).then(res => {
            //let data = (Number(res.data) / 3600).toFixed(1)

            this.setState({
                workorderAverageReceiveTime: timeUsageFormat(res.data)
            })
        }).catch(err => {
            console.error("request workorder average receive time", err)
        })
    }

    /**
     * 平均工单完成时间
     * @param county
     */
    requestWorkorderAverageFinishTime = (county = null) => {
        WorkorderApi.findWorkorderAverageFinishTime({accountId: 0, city: "赣州市", county}).then(res => {
            //let data = (Number(res.data) / 3600).toFixed(1)
            this.setState({workorderAveageFinishTime: timeUsageFormat(res.data)})
        }).catch(err => {
            console.error("request workorder average finish time failed", err)
        })
    }

    /**
     * 查询工单回访率
     * @param county
     */
    requestWorkorderEvaluated = (county = null) => {
        WorkorderApi.findWorkorderEvaluated({city: "赣州市", county}).then(res => {
            this.setState({workorderEvaluated: (Number(res.data) * 100).toFixed(1) + "%"})
        }).catch(err => {
            console.error("request workorder evaluated failed", err)
        })
    }

    /**
     * 获取工单满意度
     * @param county
     */
    requestWorkorderFeedbackScore = (county = null) => {
        WorkorderApi.findWorkorderFeedbackScore({city: "赣州市", county}).then(res => {
            this.setState({workorderFeedbackScore: res.data}, () => {
                this.chart.setOption(this.options())
            })
        }).catch(err => {
            console.error("request workorder feedback score failed", err)
        })
    }

    /**
     * 渲染工单进度字体颜色
     * @param progress
     * @returns {string}
     */
    renderWorkorderProgressColor = (progress) => {
        let result = {
            0: "#FF0000",
            1: "#00FFE1",
            5: "#F955FF",
            9: "#FFEF39",
            13: "#39A0FF",
            14: "#17C2FE",
            17: "#39FF71"
        }
        return result[progress] ?? "#FF0000";
    }

    /**
     * 移除工单监控动画
     */
    removeWorkorderMonitorTransition = () => {
        const moreWorkorder = document.getElementById("more_workorder");
        moreWorkorder.style.transition = "none"
    }

    /**
     * 添加工单监控动画
     */
    addWorkorderMonitorTransition = () => {
        const moreWorkorder = document.getElementById("more_workorder");
        moreWorkorder.style.transition = "all 1s ease-in-out"
    }

    /**
     * 查询最新工单
     */
    requestNewWorkorder = (county = null, city = "赣州市") => {
        WorkorderApi.findNewWorkorder({county, city, count: 10}).then(res => {
            this.setState({newWorkorder: [...res.data, ...res.data, ...res.data, ...res.data, ...res.data]});
        }).catch(err => {
            console.error(err)
        })
    }

    /**
     * 定时更新数据
     * @param county
     * @param city
     */
    intermissionsRequest = (county, city = "赣州市") => {
        this.newWorkorderTimer = setInterval(() => {
            this.requestNewWorkorder(county, city);
        }, 15000)
    }

    render() {
        return (<div className={styles.left_content_box}>
            <div className={styles.workorder_monitor_box}>
                <div className={styles.workorder_monitor_title} id="workorder_monitor_title">
                    工单动态监控
                </div>
                {
                    this.state.newWorkorder.length > 0 ?
                        <div className={styles.hidden_box}>
                            <div className={styles.workorder_monitor_list} id="workorder_monitor_list">
                                {this.state.newWorkorder.map((item, index) => <div
                                    className={styles.workorder_monitor_item}
                                    id={item.id}
                                    key={index}
                                    onClick={() => this.itemClick(index, item)}>
                                    <div className={styles.workorder_monitor_item_title}>
                            <span
                                className={styles.workorder_monitor_item_title_name}>{item?.clientDepartmentName}</span>
                                        <span className={styles.workorder_monitor_item_title_type}
                                              style={{color: this.renderWorkorderProgressColor(item?.progress)}}>{RenderWorkorderProgress(item?.progress)}</span>
                                    </div>
                                    <div className={styles.workorder_monitor_create_handle_name}>
                                    <span
                                        className={styles.workorder_monitor_create_name}>发起人：{item?.creatorName}</span>
                                        <span
                                            className={styles.workorder_monitor_handle_name}>处理人：{item?.handlerName}</span>
                                    </div>
                                    <div className={styles.workorder_monitor_create_time}>
                                        <span>发起时间：{item?.createTime}</span>
                                    </div>
                                </div>)}
                            </div>
                        </div> : <RenderEmptyData/>
                }
            </div>
            <div className={styles.workorder_service_box}>
                <div className={styles.workorder_service_title}>
                    <span>服务质量</span>
                </div>
                <div className={styles.workorder_service_content}>
                    <div className={styles.workorder_service_item}>
                        <span className={styles.workorder_service_item_value}>
                            <img src={require("../../../../../asset/workorder/workorder_ave_receive_time.png")} alt=""
                                 className={styles.workorder_service_item_icon}/>
                            {this.state.workorderAverageReceiveTime}
                        </span>
                        <span className={styles.workorder_service_item_label}>平均接单时间</span>
                    </div>
                    <div className={styles.workorder_service_item}>
                        <span className={styles.workorder_service_item_value}>
                            <img src={require("../../../../../asset/workorder/workorder_ave_finish_time.png")} alt=""
                                 className={styles.workorder_service_item_icon}/>
                            {this.state.workorderAveageFinishTime}
                        </span>
                        <span className={styles.workorder_service_item_label}>平均工单完成时间</span>
                    </div>
                    <div className={styles.workorder_service_item}>
                        <span className={styles.workorder_service_item_value}>
                            <img src={require("../../../../../asset/workorder/workorder_visit.png")} alt=""
                                 className={styles.workorder_service_item_icon}/>
                            {this.state.workorderEvaluated}
                        </span>
                        <span className={styles.workorder_service_item_label}>工单回访率</span>
                    </div>
                </div>
            </div>
            <div className={styles.workorder_satisfied_box}>
                <div className={styles.workorder_satisfied_title}>
                    <span>工单满意度</span>
                </div>
                <div id="workorder_satisfied_chart" className={styles.workorder_satisfied_chart}/>
            </div>
        </div>)
    }
}

export default withNavigation(WorkorderLeftContent)
