import React, {useEffect} from 'react';
import {Outlet, useNavigate} from "react-router";
import {getToken} from "../../utils/securityCheck";
import {configArea} from "../../config";
import WorkorderApi from "../../api/workorder/WorkorderApi";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-10 08:30:02
 * @description  : 主页面
 */
const Home = () => {

    const navigate = useNavigate()

    useEffect(() => {
        getAddNum()
        let {isOn } = configArea;
        console.log(isOn)
        // 获取当前路由地址
        let r = window.location.pathname
        console.log(r !== "/home/zgq/workorder")
        // 判断是否开启区县特权
        if(!isOn || r !== "/home/zgq/workorder"){
            if (!getToken(navigate)) {
                navigate("/")
            }
        }
    })
    /**
     *  获取额外自定义添加工单数量
     */
    const getAddNum = () => {
        WorkorderApi.addNum().then(response => {
            localStorage.setItem("addNum", response.data)
        }).catch(error => {
            console.error("Get workorder total filed", error);
        })
    };
    return (<>
        <Outlet/>
    </>);
};

export default Home;
