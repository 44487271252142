import React, {Component} from 'react';
import styles from "./css/right.module.css"
import * as echarts from "echarts";
import WorkorderApi from "../../../../../api/workorder/WorkorderApi";
import EdgeBorder from "../../../../../components/box/EdgeBorder";
import {timeUsageFormat} from "../../../../../utils/tool";

/**
 * @author       : AngelBeats
 * @date         : 2023-03-14 10:50:03
 * @description  : 工程师详情页面右侧内容
 */
export default class DetailRightContent extends Component {

    engineerSatisfiedChart;

    state = {
        workorderAvgFinishTime: 0,
        timeoutWorkorderNumber: 0,
        wokrorderPunctual: 0,
        avgReceiveTime: 0,
        feedbackChartX: [],
        feedbackToDissatisfiedY: [],
        feedbackToSatisfiedY: [],
        feedbackToVerySatisfiedY: [],
    }

    componentDidMount() {
        this.init();
        this.requestAccountWorkorderAvgFinishTime();
        this.requestAccountTimeoutWorkorderNumber();
        this.requestAccountWokrorderPunctual();
        this.requestAccountAvgReceiveTime();

        this.requestAccountFeedbackToDissatisfied()
        this.requestAccountFeedbackToSatisfied()
        this.requestAccountFeedbackToVerySatisfied()
    }

    /**
     * 请求用户近12个月满意度反馈: 不满意
     */
    requestAccountFeedbackToDissatisfied = () => {
        WorkorderApi.findAccountFeedbackToTwelveMonth({feedbackScore: 1, handlerId: this.props.accountId}).then(res => {
            if (this.state.feedbackChartX.length === 0) {
                this.setState({feedbackChartX: res.data.map(item => item.name).reverse()})
            }
            this.setState({feedbackToDissatisfiedY: res.data.map(item => item.value).reverse()}, () => this.engineerSatisfiedChart.setOption(this.option()))
        }).catch(err => {
            console.error("request account feedback to twelve month failed", err)
        })
    }

    /**
     * 请求用户近12个月满意度反馈: 满意
     */
    requestAccountFeedbackToSatisfied = () => {
        WorkorderApi.findAccountFeedbackToTwelveMonth({feedbackScore: 2, handlerId: this.props.accountId}).then(res => {
            if (this.state.feedbackChartX.length === 0) {
                this.setState({feedbackChartX: res.data.map(item => item.name).reverse()})
            }
            this.setState({feedbackToSatisfiedY: res.data.map(item => item.value).reverse()}, () => this.engineerSatisfiedChart.setOption(this.option()))
        }).catch(err => {
            console.error("request account feedback to twelve month failed", err)
        })
    }

    /**
     * 请求用户近12个月满意度反馈: 非常满意
     */
    requestAccountFeedbackToVerySatisfied = () => {
        WorkorderApi.findAccountFeedbackToTwelveMonth({feedbackScore: 3, handlerId: this.props.accountId}).then(res => {
            if (this.state.feedbackChartX.length === 0) {
                this.setState({feedbackChartX: res.data.map(item => item.name).reverse()})
            }
            this.setState({feedbackToVerySatisfiedY: res.data.map(item => item.value).reverse()}, () => this.engineerSatisfiedChart.setOption(this.option()))
        }).catch(err => {
            console.error("request account feedback to twelve month failed", err)
        })
    }

    /**
     * 统计图配置项
     */
    option = () => {
        let xLabel = ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'];
        return {
            title: {
                text: "近期工单数量",
                textStyle: {
                    color: "#00BDFF",
                    fontWeight: "normal",
                    fontSize: 16
                },
                left: "3%",
            },
            tooltip: {
                trigger: 'axis',
                color: "#FFFFFF",
            },
            legend: {
                right: '5%',
                top: '0',
                type: 'plain',
                textStyle: {
                    color: '#FFFFFF',
                    fontSize: 14,
                },
                data: [
                    {
                        name: '非常满意',
                    },
                    {
                        name: '满意',
                    },
                    {
                        name: '不满意',
                    },
                ],
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    axisLine: {
                        //坐标轴轴线相关设置。数学上的x轴
                        show: true,
                        lineStyle: {
                            color: '#233653',
                        },
                    },
                    axisLabel: {
                        //坐标轴刻度标签的相关设置
                        textStyle: {
                            color: '#FFFFFF',
                            padding: 16,
                            fontSize: 14,
                        },
                        formatter: function (data) {
                            return data;
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#192a44',
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                    data: this.state.feedbackChartX,
                },
            ],
            yAxis: [
                {
                    // name: '单位：笔',
                    // nameTextStyle: {
                    //     color: '#7ec7ff',
                    //     fontSize: 16,
                    //     padding: 10,
                    // },
                    min: 0,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: '#192a44',
                        },
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#233653',
                        },
                    },
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: '#FFFFFF',
                            padding: 16,
                        },
                        formatter: function (value) {
                            if (value === 0) {
                                return value;
                            }
                            return value;
                        },
                    },
                    axisTick: {
                        show: false,
                    },
                },
            ],
            series: [
                {
                    name: '非常满意',
                    type: 'line',
                    data: this.state.feedbackToVerySatisfiedY,
                    smooth: true,
                    lineStyle: {
                        color: '#92c690'
                    },
                    itemStyle: {
                        normal: {
                            color: '#92c690',
                            borderColor: '#92c690'
                        }
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#92c690b3'
                        }, {
                            offset: 1,
                            color: '#92c69003'
                        }])
                    },
                }, {
                    name: '满意',
                    type: 'line',
                    data: this.state.feedbackToSatisfiedY,
                    smooth: true,
                    lineStyle: {
                        color: '#219cab'
                    },
                    itemStyle: {
                        normal: {
                            color: '#219cab',
                            borderColor: '#219cab'
                        }
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#219cabb3'
                        },
                            {
                                offset: 1,
                                color: '#219cab03'
                            }
                        ])
                    }
                }, {
                    name: '不满意',
                    type: 'line',
                    data: this.state.feedbackToDissatisfiedY,
                    smooth: true,
                    lineStyle: {
                        color: '#6f68bf'
                    },
                    itemStyle: {
                        normal: {
                            color: '#6f68bf',
                            borderColor: '#6f68bf'
                        }
                    },
                    areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                            offset: 0,
                            color: '#6f68bfb3'
                        }, {
                            offset: 1,
                            color: '#6f68bf03'
                        }])
                    }
                }]
        };
    }

    /**
     * 获取用户平均工作时长
     */
    requestAccountWorkorderAvgFinishTime = () => {
        WorkorderApi.findWorkorderAverageFinishTime({accountId: this.props.accountId}).then(res => {
            this.setState({workorderAvgFinishTime: timeUsageFormat(res.data)})
        }).catch(err => {
            console.error("request account workorder avg finish time failed", err)
        })
    }

    /**
     * 获取用户超期工单数
     */
    requestAccountTimeoutWorkorderNumber = () => {
        WorkorderApi.findWorkorderNumber({handlerId: this.props.accountId, delay: 1}).then(res => {
            this.setState({timeoutWorkorderNumber: res.data})

        }).catch(err => {
            console.error("request account timeout workorder number failed", err)
        })
    }

    /**
     * 获取工程师守时率
     */
    requestAccountWokrorderPunctual = () => {
        WorkorderApi.findAccountWokrorderPunctual(this.props.accountId).then(res => {
            this.setState({wokrorderPunctual: (res.data * 100).toFixed(3) + "%"})
        }).catch(err => {
            console.error("request account workorder punctual failed", err)
        })
    }

    /**
     * 获取用户平均接单时长
     */
    requestAccountAvgReceiveTime = () => {
        WorkorderApi.findAccountAvgReceiveTime(this.props.accountId).then(res => {
            this.setState({avgReceiveTime: timeUsageFormat(res.data)})
        }).catch(err => {
            console.error("request account avg receive time failed", err)
        })
    }

    /**
     * 初始化图表
     */
    init = () => {
        this.engineerSatisfiedChart = echarts.init(document.getElementById("engineer_satisfied_chart"))
    }

    render() {

        const {
            workorderAvgFinishTime,
            timeoutWorkorderNumber,
            wokrorderPunctual,
            avgReceiveTime
        } = this.state

        return (<div className={styles.engineer_detail_right_view}>
            <div className={styles.engineer_detail_statistics_view}>
                <div className={styles.engineer_detail_statistics_finish_time}>
                    <span className={styles.engineer_detail_statistics_value}>{workorderAvgFinishTime}</span>
                    <span className={styles.engineer_detail_statistics_label}>平均完成工单时间</span>
                </div>
                <div className={styles.engineer_detail_statistics_timeout_num}>
                    <span className={styles.engineer_detail_statistics_value}>{timeoutWorkorderNumber}</span>
                    <span className={styles.engineer_detail_statistics_label}>超时工单数</span>
                </div>
                <div className={styles.engineer_detail_statistics_on_time}>
                    <span className={styles.engineer_detail_statistics_value}>{wokrorderPunctual}</span>
                    <span className={styles.engineer_detail_statistics_label}>工单守时率</span>
                </div>
                <div className={styles.engineer_detail_statistics_response_time}>
                    <span className={styles.engineer_detail_statistics_value}>{avgReceiveTime}</span>
                    <span className={styles.engineer_detail_statistics_label}>平均工单响应时长</span>
                </div>
                <EdgeBorder/>
            </div>
            <div className={styles.engineer_detail_right_satisfied_chart}>
                <div id="engineer_satisfied_chart" className={styles.engineer_satisfied_chart}/>
                <span className={styles.card_top_left_corner}/>
                <span className={styles.card_top_right_corner}/>
                <span className={styles.card_bottom_left_corner}/>
                <span className={styles.card_bottom_right_corner}/>
            </div>
        </div>)
    }
}
