import isDevEnvironment from "./utils/isDevEnvironment";
import {NODEENV} from "./utils/environment";

// const configEnv = isDevEnvironment ? "dev" : "prod";
//const configEnv = NODEENV();
const configEnv = "prod";

const configEnvs = {
    dev: {
        //url: "http://127.0.0.1:8005",
        // url: "http://localhost:10086",
        //url: "http://192.168.2.193:8005"
        url: "http://192.168.2.20:9005",
    },
    prod: {
        url: "https://api.xcfw.cn",
        //url: "http://218.95.16.10:8088/backend",
        //url: "http://192.168.2.97:9005/",
        // url: "http://218.64.219.61:6001", /*测试环境*/
    }
};
// 配置区县
export const configArea = {
    city: "赣州市",
    county: "章贡区",
    isOn:true
}
const configUrl = {
    url: configEnvs[configEnv].url
};

global.config = configUrl;
window.config = configUrl;

export default configUrl;
